import React from 'react'
import { UserProfile } from './components/body';
import { pageTitle } from './profile.json';
import './styles.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Recommendations, Bookmarks } from './pages/recomendations';

import "./i18n";
document.title = pageTitle;

export const App = () => {
  return (
  <React.Fragment>
    <Router>
      <Switch>
        <Route path="/product-recommendations" component={Recommendations} />
        <Route path="/bookmarks" component={Bookmarks} />
        <Route path="/08122023" render={() => {
          window.location.href = "https://docs.google.com/presentation/d/1nUf55eFPiTQjrdhjlTPXQlT2pHGdx4XcXKGZHlJhEW8/edit#slide=id.p"
        }} />
        <Route exact path="/">
          <UserProfile />
        </Route>
      </Switch>
    </Router>
  </React.Fragment>
)
}
