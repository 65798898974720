import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          profession: "Software Engineer",
          bookmarks: "My bookmarks",
          products: "Products I like",
          hire: "Want to build something?",
          hire_email: "Send me an e-mail",
        }
      },
      pt: {
        translations: {
          profession: "Engenheiro de Software",
          bookmarks: "Marcações",
          products: "Produtos que eu gosto",
          hire: "Quer construir software?",
          hire_email: "Me envie um e-mail",
        }
      }
    },
    fallbackLng: "pt",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });
