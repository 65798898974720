import React from 'react';
import styled from 'styled-components';
import {Header} from '../header';
import lastPostsHtml from '../../blog/last_posts.html';
import {name} from '../../profile.json';
import {useTranslation} from 'react-i18next';

export const StyledContent = styled.main`
  text-align:center;
  padding:0 1rem;
`;

export const Wrapper = styled.div`
  position: relative;
  top: 100px;
  display: flex;
  flex-direction: column;
  min-height: calc(100% - (300px + 100px));
  `;

export const UserProfile = () => {
  const {t, i18n} = useTranslation();
  return (
    <React.Fragment>
      <Header />
      <Wrapper>
        <StyledContent>
          <h1 className='highlighted'>{name}</h1>
          <p className='highlighted'>{t("profession")} </p>
          <br/>
          <a className='tag' title='bookmarks' href='https://github.com/luanraithz'>💻 Software</a>
          <a className='tag' title='bookmarks' href='/bookmarks'>🔖 {t('bookmarks')}</a>
          <a className='tag' title='products' href='/product-recommendations'>💸 {t('products')}</a>
          <br/>
          <br/>
          <p>
            {t('hire')}
            <a className='tag' title='products' href='mailto:software@raithz.com'> {t('hire_email')} </a>
          </p>
          <div dangerouslySetInnerHTML={{__html: lastPostsHtml}} />
        </StyledContent>
      </Wrapper>
    </React.Fragment>
  );
};
