import React from 'react'
import { Link } from 'react-router-dom'
import { Wrapper, StyledContent } from '../components/body'

export const List = ({ title, children }) => {
  return (
    <Wrapper>
      <StyledContent>
        <Link to="/"> Back to home </Link>
        <br />
        <br />
        <h1> {title} </h1>
        <br />
        <br />
        {children}
      </StyledContent>
    </Wrapper>
  )
}
